import React, { useState } from 'react'
import "./QuestionView.css";

import HomeIcon from '../images/home';
import ApartmentIcon from '../images/apartment';


function QuestionView({Questions, currentQuestion, handleQuestionSelectClick, handleQuestionBackClick, Animation}) {
    const getAllQuestions = Questions.questions;
    const questionHeadline = Questions.questions[currentQuestion].question;

    const [checkboxValues, setCheckboxValues] = useState('');

    const getImage = (icon) => {

        switch (icon){
            case "home":
                return <HomeIcon/>;
                break;
            case "apartment":
                return <ApartmentIcon/>;
                break;
            default:
                return <HomeIcon/>;
        }
    }

    const onCheckedValue = (e) => {
        const { checked, value } = e.target;
        if(checked){
            setCheckboxValues([...checkboxValues, value])
        }else{
            setCheckboxValues(checkboxValues.filter((item) => item !== value));
        }    
    }

    
    return (
        <div className={ ( currentQuestion != 2 ? ("question-view question-view-md") : ("question-view question-view-lg")) + ( Animation ? ' animation-start' : " animation-stop") }>
            <div className="question-headline">
                {questionHeadline}
            </div>  
            <div className={currentQuestion != 2 ? ("question-answer-handler") : ("question-answer-handler-checkbox") }>
            {getAllQuestions[currentQuestion].answers.map(({title, check, icon = null}, index) => (
                
                <button key={index} className="question-answer-item-handler" disabled={Animation} >
                    {title && 
                    <div className="question-item" onClick={() => handleQuestionSelectClick(title)}>
                        {icon && <i>
                            {getImage(icon)}
                        </i>}
                        <span>{title}</span>
                    </div>
                    }

                    {check && 
                    <div className="checkbox-item container-box">
                        <input type="checkbox" id={check} name={check} value={check} onChange={onCheckedValue}></input>
                        <label className="checkmark" htmlFor={check}>{check}</label>
                    </div>
                    }
                </button>

            ))}
            </div>
            <div className="submit-handler">
            {currentQuestion >= 1 && <div className="back-btn"> <button onClick={() => handleQuestionBackClick()}> Zurück </button> </div> }
            {currentQuestion == 2 && <div className="next-btn"> <button onClick={() => handleQuestionSelectClick(checkboxValues)}> Weiter </button></div>}
            </div>
        </div>
    )
}

export default QuestionView
