import React, { useState } from 'react';
import axios from 'axios';

import "./FormularView.css";
import ProgressBar from './ProgressBar';

function FormularView({SelectedQuestion}) {
    const valueSelectedQuestion = SelectedQuestion.toString();
    const [isLoading, setIsLoading] = useState(false);
    const [sendDataSuccess, setSendDataSuccess] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        vorname: "",
        nachname: "",
        zip: "",
        place: "",
        email: "",
        phone: "",
        subject: valueSelectedQuestion
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({...formData, [name]: value});
        setErrors({ ...errors, [name]: "" });
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = {};
        const formDataObj = new FormData();

        
        if (formData.vorname.trim() === "") {
            validationErrors.vorname = "Bitte ausfüllen!";
        }
        if (formData.nachname.trim() === "") {
            validationErrors.nachname = "Bitte ausfüllen!";
        }
        if (formData.zip.trim() === "") {
            validationErrors.zip = "Bitte ausfüllen!";
        }
        if (formData.place.trim() === "") {
            validationErrors.place = "Bitte ausfüllen!";
        }
        if (formData.email.trim() === "") {
            validationErrors.email = "Bitte ausfüllen!";
        }
        if (formData.phone.trim() === "") {
            validationErrors.phone = "Bitte ausfüllen!";
        }

        formDataObj.append('vorname', formData.vorname);
        formDataObj.append('nachname', formData.nachname);
        formDataObj.append('email', formData.email);
        formDataObj.append('phone', formData.phone);
        formDataObj.append('zip', formData.zip);
        formDataObj.append('place', formData.place);
        formDataObj.append('subject', formData.subject);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        }else{
            
            console.log(window.location.origin);
            setIsLoading(true);
            axios({
                method: 'post',
                url: window.location.origin + '/backend/send.php',
                headers: {'Content-Type': 'multipart/form-data' },
                data: formDataObj
            }).then((response) => {
                if (response.status == '200') {
                    //document.querySelector(".success").style.cssText = "display: block; text-align: center;";
                    setSendDataSuccess(true);
                    document.body.className += ' app-end';
                    window.scrollTo(0, 0);

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'rsmFormStep',
                        'rsmStepNumber': '4',
                        'rsmStepName': 'Kontaktdaten',
                        'rsmStepType': 'conversion',
                        'rsmPropertyType': SelectedQuestion[0],
                        'rsmPropertySize': SelectedQuestion[1],
                        'rsmAddonService': SelectedQuestion[2].toString(),
                        
                        'rsmEmail': formData.email,
                        'rsmZip': formData.zip,
                        'rsmCity': formData.place
        
                    });

                }else{
                    console.log('Nein');
                }
            }).catch((error) => {
                console.log('Error submittig from:', error);
                setIsLoading(false);
            })

            console.log('end');
            return false;

        }


    }

    return (
        <div className="form-wrap">
            {!sendDataSuccess && 
                <>
                <ProgressBar />
                <div className="question-headline">
                    Ihre Kontaktdaten
                </div>  
                </>
            }  
            <form onSubmit={handleSubmit}>
                {sendDataSuccess && <div className="success display-none">Vielen Dank für Ihre Anfrage ­- wir melden <br/>uns schnellstmöglich bei Ihnen!</div>}
                {!sendDataSuccess &&
                <>
                <div className="form-input-handler">
                    <div className="form-input-item form-input-item-row-1">
                        <div className="item-handler">
                            <label htmlFor="vorname">Vorname*
                            {errors.vorname && (
                                <span className="validation-message">{errors.vorname}</span>
                            )}
                            </label>
                                <input 
                                    type="text"
                                    id="vorname"
                                    className="full-width"
                                    name="vorname"
                                    value={formData.vorname}
                                    onChange={handleInputChange}
                                />
                        
                        </div>
                        <div className="item-handler">
                            <label htmlFor="nachname">Name*
                            {errors.nachname && (
                                <span className="validation-message">{errors.nachname}</span>
                            )}
                            </label>
                                <input 
                                    type="text"
                                    id="nachname"
                                    className="full-width"
                                    name="nachname"
                                    value={formData.nachname}
                                    onChange={handleInputChange}
                                />
                   
                        </div>
                        <div className="item-handler">
                            <label htmlFor="zip">PLZ*
                            {errors.zip && (
                                <span className="validation-message">{errors.zip}</span>
                            )}
                            </label>
                                <input 
                                    type="text"
                                    id="zip"
                                    className="full-width"
                                    name="zip"
                                    value={formData.zip}
                                    onChange={handleInputChange}
                                />
                         
                        </div>
                        <div className="item-handler">
                            <label htmlFor="place">Ort*
                            {errors.place && (
                                <span className="validation-message">{errors.place}</span>
                            )}           
                            </label>
                                <input 
                                    type="text"
                                    id="place"
                                    className="full-width"
                                    name="place"
                                    value={formData.place}
                                    onChange={handleInputChange}
                                />
                         
                        </div>
                    </div>

                    <div className="form-input-item form-input-item-row-2">
                        <div className="item-handler">
                            <label htmlFor="email">E-Mail*:
                            {errors.email && (
                                <span className="validation-message">{errors.email}</span>
                            )}
                            </label>
                            <div className="item-handler-input max-input">
                                <input type="email" id="email" className="full-width" name="email" 
                                    value={formData.email} 
                                    onChange={handleInputChange} 
                                />
                            </div>
                        </div>
                        <div className="item-handler">
                            <label htmlFor="phone">
                            Telefonnummer*:{" "}
                            {errors.phone && (
                                <span className="validation-message">{errors.phone}</span>
                            )}
                            </label>
                            <div className="item-handler-input">
                                <input type="text" id="name" className="full-width" name="phone" 
                                    value={formData.phone} 
                                    onChange={handleInputChange} 
                                />            
                            </div>
                        </div>
                    </div>
                    


                </div>

                <div className="submit-handler">
                    <button
                        type="submit"
                        disabled={isLoading}
                        className="next-btn"
                    >
                        Angebot anfordern
                    </button>
                </div>
                </>
                }
            </form>
        </div>
    )
}

export default FormularView
